import React from "react"
import Layout from "../components/layout"
import CenteredBody from "../components/centered-body";

export default function Home() {
    return (
        <Layout>
            <CenteredBody>
                <div className="article">
                    <h1>Spun acasă, deși ceilalți aud la țară</h1>
                    <p>
                        Mă trage ața periodic acolo unde energia copilăriei mele îmi încarcă bateriile sufletului golit
                        de sticla și de plasticul orașului unde am fost, sunt și voi fi pentru totdeauna un străin venit
                        aici
                        doar pentru a câștiga niște bani mai mulți decât aș fi făcut-o acolo unde, practic, mi-e locul.
                        Sunt
                        fericit aici, departe? Nu. Sunt însă împlinit, serviciul oferindu-mi confortul atât cât să-mi
                        permit
                        anumite lucruri pe care acolo nu mi le-aș fi putut permite. Însă orașul mi-a ucis o parte din
                        cumsecădenia cu care am crescut. Aici vacarmul goanei după bani nu te lasă să fii sentimental și
                        orice urmă de bună creștere va fi taxată, văzută fiind ca o slăbiciune de care restul trebuie să
                        profite neapărat, ca o haită de lupi simțind miros de sânge. Și a trebuit să port întreaga mea
                        existență masca indiferenței pentru a-mi face loc printre gladiatorii betoanelor, mercenari
                        aduși
                        din toate colțurile țării pentru a face un ban. O las în Barieră de câte ori plec acasă. Lumea
                        mea
                        nu mă știe decât așa cum sunt eu și nu risc să plec mascat acolo unde toată lumea e așa cum
                        trebuie să fie: deschisă, sinceră și bună. Aici, în țara mea, primesc bună ziua de la toată
                        lumea cu
                        care mă întâlnesc. Aici sunt al cuiva, faptele mele fiindu-le atribuite și înaintașilor mei,
                        cinstindu-
                        le memoria. De aceea eu vin aici acasă, nu vin aici la țară”.
                    </p>
                    <p style={{fontStyle: `italic`}}>(Cornel Teodoru, fragment din volumul Gânduri de om, Editura
                        InfoRapArt,
                        2019)</p>
                </div>
            </CenteredBody>
        </Layout>
    );
}
